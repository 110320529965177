import axios from 'axios';
import { ListOptions, ListOption } from '@/utility/ListOptions';
import { axiosGetWithoutSpin } from '@/utility/axios-utilities';
import { splitName, toName } from '@/utility/PersonalName';
import { loadClassification, Classification } from '@/utility/Classification';
import { AuthInfo } from '@/utility/login';
import { bCodeLength, bCodeBaseLength, handN, handH1, handH2, handSys } from '@/utility/consts/DbTables';
import { isValidMail, isValidTel } from '@/utility/valid';
import { toDateKey } from '@/utility/date';
import { SaveResultItem } from '@/utility/save-result';
import { initParent, Parent, ParentEditItem } from '@/utility/AddParentDialog';
import { alert } from '@/utility/messages';
import { isValidParent } from '@/utility/AddParentDialog';

//  移動 zzz  (DbTables.ts)
const nameLength = 26; //< 漢字人名(保護者共通) 最大長
export const nameALength = 10; //< 姓文字列長
export const nameBLength = 15; //< 名文字列長
export const cNTinyLength = 4;
export const classLength = 8; //  クラス名の長さ
export const mailLength = 60;
export const telLength = 20;
export const memoLength = 32;

export const minDate = '1900-01-01';
export const maxDate = '2099-12-31';

export const monthDateOpts: ListOption<string>[] = [
  { text: '01', value: '01' },
  { text: '16', value: '16' },
];

export const monthCloseOpts: ListOption<string>[] = [
  { text: '12', value: '12' },
  { text: '13', value: '13' },
  { text: '25', value: '25' },
  { text: '27', value: '27' },
  { text: '28', value: '28' },
];

// --- transfer
export interface ChildSearchItem {
  ccode: number;
  cname: string;
  cnkana: string;
  age: number;
  cmonthly: number;
  chandicap: number;
  cpname: string;
  cptel: string;
  cpmail: string;
  bcode: string;
  bkname: string;
  centryday: string;
  cexitday: string;
  exited: boolean;
  cclass: string;
  cStDate: string;
}

export interface ChildEditItem {
  ccode: number;
  cstdate: string;
  ceddate: string;
  cbcode: string;
  cname: string;
  cntiny: string;
  cnkana: string;
  csex: number;
  cbirthday: string;
  cfood: number;
  chandicap: number;
  centryday: string;
  cexitday: string;
  cmonthly: number;
  cokinder: number;
  cmonthdate: string;
  cmonthclose: string;
  cpname: string;
  cpkana: string;
  cclass: string;
  cpmail: string;
  cptel: string;
  cmemo: string;
  pkStDates: string[]; //< 取得時のみ利用
}

export interface FamilyItem {
  child: ChildEditItem;
  selected: boolean;
  parent: ParentEditItem;
}

interface BaseNameItem {
  bCode: string;
  bName1: string;
  bName2: string;
  bkName: string;
  bnTiny: string;
  classes: string[];
}

// --- vue controll
export interface PageData {
  classifications: Classification[];
}
export interface Child {
  cCode: number;
  cStDate: string;
  cEdDate: string;
  cBCode: string;
  cNameA: string;
  cNameB: string;
  cNTiny: string;
  cNKanaA: string;
  cNKanaB: string;
  cSex: number;
  cBirthday: string;
  cFood: number;
  cHandicap: number;
  cEntryDay: string;
  cExitDay: string;
  cMonthly: number;
  cOKinder: number;
  cMonthdate: string;
  cMonthclose: string;
  cPNameA: string;
  cPNameB: string;
  cPKanaA: string;
  cPKanaB: string;
  cClass: string;
  cPMail: string;
  cPTel: string;
  cMemo: string;
  pkStDate: string; //< ロード時のcStDate
  pkStDates: Date[]; //< 全cStDate(Key)

  sysHandcap: number; //< 0～2...値をcHandicapに保存 9...sysCateogryをcHandicapに保存
  sysCategory: number;

  dbOkCCode: number; //< insert可能なCCode
}

export interface ChildRow {
  cCode: number;
  cName: string;
  cNKana: string;
  age: number;
  cMonthly: number;
  cHandicap: number;
  cPName: string;
  cPTel: string;
  cPMail: string;
  cclass: string;
  bName: string;
  cEntryDay: string;
  cExitDay: string;
  exited: boolean;
  cStDate: string;

  sysHandcap: number; //< 0～2...値をcHandicapに保存 9...sysCateogryをcHandicapに保存
  sysCategory: number;

  email: boolean;
}

export interface BaseData {
  keyword: string;
  selectList: ListOptions;
  baseNames: BaseName[];
}

export type BaseName = BaseNameItem;

export interface PageModel {
  monthlies: Record<number, string>;
  foods: Record<number, string>;
  sexes: Record<number, string>;
  anothers: Record<number, string>;
  handicaps: Record<number, string>;
  edited: boolean; //< 児童編集
  parent: Parent;
  selectedParent: boolean;
}

export function initPageModel(): PageModel {
  const model: PageModel = {
    monthlies: {},
    foods: {},
    sexes: {},
    anothers: {},
    handicaps: {},
    edited: false,
    parent: initParent(),
    selectedParent: false,
  };

  return model;
}

//  --- list view
export const masterChildrenSearchCondtionName = 'ChildMSearchCond';

export interface ChildSearchCondition {
  all: boolean;
  keyword: string;
}

export function initChildSearchCondition(): ChildSearchCondition {
  const res: ChildSearchCondition = {
    all: false,
    keyword: '',
  };
  return res;
}

//  --- functions
export function initChild(): Child {
  const today = new Date();
  const beginOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return {
    cCode: 0,
    cStDate: toDateKey(beginOfMonth),
    cEdDate: '',
    cBCode: '',
    cNameA: '',
    cNameB: '',
    cNTiny: '',
    cNKanaA: '',
    cNKanaB: '',
    cSex: 1,
    cBirthday: `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    cFood: 1,
    cHandicap: 0,
    cEntryDay: toDateKey(beginOfMonth),
    cExitDay: '',
    cMonthly: 1,
    cOKinder: 0,
    cMonthdate: '01',
    cMonthclose: '25',
    cPNameA: '',
    cPNameB: '',
    cPKanaA: '',
    cPKanaB: '',
    cClass: '',
    sysHandcap: 0,
    sysCategory: 10, //< 本部
    cPMail: '',
    cPTel: '',
    cMemo: '',
    dbOkCCode: 0,
    pkStDate: '',
    pkStDates: [],
  };
}
export async function loadChild(cCode: string, pkstdate: string): Promise<Child> {
  const child = (
    await axios.get<ChildEditItem>('/api/MasterChild/' + cCode, {
      params: {
        pkstdate: pkstdate,
      },
    })
  ).data;
  const [cNKanaA, cNKanaB] = splitName(child.cnkana);
  const [cNameA, cNameB] = splitName(child.cname);
  const [cPNameA, cPNameB] = splitName(child.cpname);
  const [cPKanaA, cPKanaB] = splitName(child.cpkana);
  return Promise.resolve({
    cCode: child.ccode,
    cStDate: child.cstdate,
    cEdDate: child.ceddate,
    cBCode: child.cbcode,
    cNameA: cNameA,
    cNameB: cNameB,
    cNTiny: child.cntiny,
    cNKanaA: cNKanaA,
    cNKanaB: cNKanaB,
    cSex: child.csex,
    cBirthday: child.cbirthday,
    cFood: child.cfood,
    cHandicap: child.chandicap,
    cEntryDay: child.centryday,
    cExitDay: child.cexitday,
    cMonthly: child.cmonthly,
    cOKinder: child.cokinder,
    cMonthdate: child.cmonthdate,
    cMonthclose: child.cmonthclose,
    cPNameA: cPNameA,
    cPNameB: cPNameB,
    cPKanaA: cPKanaA,
    cPKanaB: cPKanaB,
    cClass: child.cclass,
    cPMail: child.cpmail,
    cPTel: child.cptel,
    cMemo: child.cmemo,
    pkStDate: child.cstdate, //< loadで指定したStDate
    pkStDates: child.pkStDates.map(v => v.dateTextToDate()),

    sysHandcap: isRealHandcap(child.chandicap) ? child.chandicap : handSys,
    sysCategory: isRealHandcap(child.chandicap) ? 10 : child.chandicap, //< 10...本部
    dbOkCCode: 0,
  });
}

export async function isExistRecord(cCode: number, stDate: string): Promise<boolean> {
  const strDate = toDateKey(stDate.dateTextToDate());
  const res = await axios.get<boolean>(`/api/MasterChild/isExist/${cCode}`, {
    params: {
      pkstdate: strDate,
    },
  });
  return res.data;
}

export async function loadChildren(all: boolean, keyword: string): Promise<ChildRow[]> {
  const response = await axios.get<ChildSearchItem[]>('/api/MasterChild/search', {
    params: {
      all: all,
      keyword: keyword,
    },
  });
  const rows = response.data.map(v => {
    const row: ChildRow = {
      cCode: v.ccode,
      cName: v.cname,
      cNKana: v.cnkana,
      age: v.age,
      cMonthly: v.cmonthly,
      cHandicap: v.chandicap,
      cPName: v.cpname,
      cPTel: v.cptel,
      cPMail: v.cpmail,
      bName: `${v.bcode} ${v.bkname} `,
      cEntryDay: v.centryday,
      cExitDay: v.cexitday,
      exited: v.exited,
      cclass: v.cclass,
      cStDate: v.cStDate,

      sysHandcap: isRealHandcap(v.chandicap) ? v.chandicap : handSys,
      sysCategory: isRealHandcap(v.chandicap) ? 10 : v.chandicap, //< 10...本部

      email: false,
    };
    return row;
  });
  return Promise.resolve(rows);
}

export async function addChild(cCode: number, child: Child, selectedParent: boolean, parent: Parent): Promise<SaveResultItem> {
  const item: FamilyItem = {
    child: toEditItem(child),
    parent: toParentItem(parent),
    selected: selectedParent,
  };
  const response = await axios.post<SaveResultItem>('/api/MasterChild/family/' + cCode, item);
  return response.data;
}

function toParentItem(parent: Parent): ParentEditItem {
  const parentItem: ParentEditItem = {
    pcode: parent.pCode,
    pstdate: parent.pStDate,
    peddate: parent.pEdDate,
    pname: toName(parent.pNameA, parent.pNameB),
    pkana: toName(parent.pKanaA, parent.pKanaB),
    pmail: parent.pMail.trim(),
    ptel: parent.pTel.trim(),
    ppassword: parent.pPassword,
    hpassword: parent.hPassword,
    pmemo: parent.pMemo?.trim(),
    custno: parent.custNo?.trim(),
    pkStDates: [],
  };

  return parentItem;
}

export async function updateChild(cCode: number, child: Child, pcode: number): Promise<SaveResultItem> {
  const response = await axios.put<SaveResultItem>('/api/MasterChild/' + cCode, toEditItem(child), {
    params: {
      pkStDate: child.pkStDate, //< 編集画面のロード時の値(pk)を渡す yyyy-MM-dd形式
      pcode: pcode,
    },
  });
  return response.data;
}

function toEditItem(child: Child): ChildEditItem {
  const item: ChildEditItem = {
    ccode: child.cCode,
    cstdate: child.cStDate,
    ceddate: child.cEdDate,
    cbcode: child.cBCode.trim(),
    cname: toName(child.cNameA, child.cNameB),
    cntiny: child.cNTiny.trim(),
    cnkana: toName(child.cNKanaA, child.cNKanaB),
    csex: child.cSex,
    cbirthday: child.cBirthday,
    cfood: child.cFood,
    chandicap: child.cHandicap,
    centryday: child.cEntryDay,
    cexitday: child.cExitDay,
    cmonthly: child.cMonthly,
    cokinder: child.cOKinder,
    cmonthdate: child.cMonthdate,
    cmonthclose: child.cMonthclose,
    cpname: toName(child.cPNameA, child.cPNameB),
    cpkana: toName(child.cPKanaA, child.cPKanaB),
    cclass: child.cClass.trim(),
    cpmail: child.cPMail.trim(),
    cptel: child.cPTel.trim(),
    cmemo: child.cMemo.trim(),
    pkStDates: [],
  };
  return item;
}

// --- page表示に必要なデータをDBから取得
export async function loadPageModel(model: PageModel) {
  const args: string[] = ['登録', '離乳', '性別C', '他園', '特殊保育児童'];
  loadClassification(args).then(classes => {
    model.monthlies = {};
    classes.filter(v => v.kcode === '登録').forEach(v => (model.monthlies[v.kinid] = v.kname));
    model.foods = {};
    classes.filter(v => v.kcode === '離乳').forEach(v => (model.foods[v.kinid] = v.kname));
    model.sexes = {};
    classes.filter(v => v.kcode === '性別C').forEach(v => (model.sexes[v.kinid] = v.kname));
    model.anothers = {};
    classes.filter(v => v.kcode === '他園').forEach(v => (model.anothers[v.kinid] = v.kname));
    model.handicaps = {};
    classes.filter(v => v.kcode === '特殊保育児童').forEach(v => (model.handicaps[v.kinid] = v.kname));
  });
}

//  --- validation

export function checkValidNew(child: Child, parent: Parent) {
  return checkCCode(child) && checkValidEdit(child) && isValidParent(parent);
}

export function checkValidEdit(child: Child) {
  return checkCBCode(child) && checkStDate(child) && checkEntryDate(child) && checkExitDate(child);
  /* org 
  return (
    checkCNKanaA(child) &&
    checkCNKanaB(child) &&
    checkCNameA(child) &&
    checkCNameB(child) &&
    checkCNTiny(child) &&
    checkCPKanaA(child) &&
    checkCPKanaB(child) &&
    checkCPNameA(child) &&
    checkCPNameB(child) &&
    checkCBCode(child) &&
    checkCPMail(child) &&
    checkCPTel(child) &&
    checkCMemo(child) &&
    child.cClass.length < classLength && 
    child.cEntryDay.length > 0 && 
    child.cStDate.length > 0
  );
  */
}

export function checkCCode(child: Child): boolean {
  return child.dbOkCCode > 0 && child.cCode === child.dbOkCCode;
}

export function checkCNameA(child: Child): boolean {
  return isValidNameA(child.cNameA);
}

export function checkCNameB(child: Child): boolean {
  return isValidNameB(child.cNameB);
}

export function checkCNKanaA(child: Child): boolean {
  return isValidKanaA(child.cNKanaA);
}

export function checkCNKanaB(child: Child): boolean {
  return isValidKanaB(child.cNKanaB);
}

export function checkCNTiny(child: Child): boolean {
  const str = child.cNTiny.trim();
  return str.length > 0 && str.length <= cNTinyLength;
}

export function checkCPNameA(child: Child): boolean {
  return isValidNameA(child.cPNameA);
}

export function checkCPNameB(child: Child): boolean {
  return isValidNameB(child.cPNameB);
}

export function checkCPKanaA(child: Child): boolean {
  return isValidKanaA(child.cPKanaA);
}

export function checkCPKanaB(child: Child): boolean {
  return isValidKanaB(child.cPKanaB);
}

export function checkCBCode(child: Child): boolean {
  const str = child.cBCode.trim();
  return str.length > 0 && str.length <= bCodeLength;
}

export function checkCPMail(child: Child): boolean {
  return !child.cPMail || isValidCPMail(child.cPMail);
}

export function checkCPTel(child: Child): boolean {
  return !child.cPTel || isValidCPTel(child.cPTel);
}

export function checkCMemo(child: Child): boolean {
  return isValidCMemo(child.cMemo);
}

export function checkEntryDate(child: Child): boolean {
  if (!child.cEntryDay) return false;
  return minDate <= child.cEntryDay && child.cEntryDay < maxDate;
}

export function checkExitDate(child: Child): boolean {
  if (!child.cExitDay) return true;
  return minDate <= child.cExitDay && child.cExitDay < maxDate;
}

export function checkStDate(child: Child): boolean {
  if (!child.cStDate) return false;
  return minDate <= child.cStDate && child.cStDate < maxDate;
}

export async function getMaxCCode(child: Child) {
  axios.get<number>('/api/MasterChild/maxccode').then(response => {
    child.cCode = response.data + 1;
  });
}

export async function checkExistCCode(child: Child) {
  if (typeof child.cCode !== 'number') return;
  const ccd: number = child.cCode;
  axiosGetWithoutSpin<number>('/api/MasterChild/existccode/' + ccd).then(response => {
    child.dbOkCCode = response.data;
  });
}

//  児童・保護者共通
export function isValidKana(kana: string): boolean {
  const str = kana.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.indexOf(' ') > 0 && str.length > 0 && str.length <= nameLength;
}

export function isValidKanaA(kana: string): boolean {
  const str = kana.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.indexOf(' ') < 0 && str.length > 0 && str.length <= nameALength;
}

export function isValidKanaB(kana: string): boolean {
  const str = kana.trim().replaceAll('　', ' ');
  const res = str.match(/^[ぁ-んー\s]*$/);
  return res !== null && str.length > 0 && str.length <= nameBLength;
}

//  姓 児童・保護者共通
function isValidNameA(nameA: string): boolean {
  const str = nameA.trim().replaceAll('　', ' ');
  return str.indexOf(' ') < 0 && str.length > 0 && str.length <= nameALength;
}

//  名 児童・保護者共通
function isValidNameB(nameB: string): boolean {
  const str = nameB.trim().replaceAll('　', ' ');
  return str.length > 0 && str.length <= nameBLength;
}

export function isValidCPMail(cPMail: string): boolean {
  return isValidMail(cPMail, mailLength);
}

export function isValidCPTel(cPTel: string): boolean {
  return isValidTel(cPTel, telLength);
}

export function isValidCMemo(cMemo: string): boolean {
  return cMemo.trim().length <= memoLength;
}

//  --- 拠点選択
export const initBaseData: BaseData = {
  keyword: '',
  selectList: {},
  baseNames: [],
};

export async function loadBases(baseData: BaseData, authInfo: AuthInfo): Promise<void> {
  const response = await axios.get<BaseNameItem[]>('/api/MasterBase/NameOptions');
  const rows = response.data.map(v => {
    const row: BaseName = {
      bCode: v.bCode,
      bName1: v.bName1,
      bName2: v.bName2,
      bkName: v.bkName,
      bnTiny: v.bnTiny,
      classes: [...v.classes],
    };
    return row;
  });

  const isOffice = authInfo.mbjType === '8' || authInfo.sbjType === '8';
  const set = new Set<string>();
  set.add(authInfo.staffMainBaseCode.slice(0, bCodeBaseLength));
  if (authInfo.staffSubBaseCode.length === bCodeLength) {
    set.add(authInfo.staffSubBaseCode.slice(0, bCodeBaseLength));
  }
  const baseList: string[] = [...set];
  const baseNames = rows.filter(v => isOffice || baseList.includes(v.bCode.slice(0, bCodeBaseLength)));

  baseData.keyword = '';
  baseData.baseNames = [...baseNames];
  updateBaseList(baseData);
}

export function updateBaseList(baseData: BaseData) {
  const list: ListOptions = {};
  const k = baseData.keyword.trim();
  baseData.baseNames
    .filter(
      v =>
        k === '' ||
        v.bCode === k ||
        v.bName1.indexOf(k) >= 0 ||
        v.bName2.indexOf(k) >= 0 ||
        v.bkName.indexOf(k) >= 0 ||
        v.bnTiny.indexOf(k) >= 0
    )
    .forEach(v => {
      list[v.bCode] = v.bnTiny;
    });
  baseData.selectList = list;
}

export function getClasses(baseData: BaseData, bCode: string): string[] {
  const find = baseData.baseNames.find(v => v.bCode === bCode);
  if (find === undefined) return [];
  return find.classes;
}

function isRealHandcap(handcap: number): boolean {
  return [handN, handH1, handH2].indexOf(handcap) >= 0;
}

export function getRealAge(year: number, month: number, day: number): number {
  const today = new Date();
  const age = today.getFullYear() - year;
  return today < new Date(today.getFullYear(), month - 1, day) ? age - 1 : age;
}

//  未使用
export function compChaild(childA: Child, childB: Child): boolean {
  const aKeys = Object.keys(childA);
  const bKeys = Object.keys(childB);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  aKeys.some(key => childA[key as keyof Child] !== childB[key as keyof Child]);
  return true;
}

// ---
export const askEditMsg = '編集デーが保存されていません。保存せず移動しますか？';
export const askCreate = '新たに履歴データが作成されようとしています。保存しますか？';
export const askadd = '保護者情報が登録されていません。';
